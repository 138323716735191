<template>
  <Menu awImgSrc="AW1.svg" activeItem="99"/>
  <router-view :key="$route.fullPath"/>
</template>

<script>
import Menu from './components/Menu.vue'

export default {
  components: {
    Menu
  }
}
</script>

<style>
@font-face {
	font-family: 'Yantramanav';
	src: url('./assets/fonts/Yantramanav-Bold.ttf');
	font-weight: 600;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Yantramanav';
	src: url('./assets/fonts/Yantramanav-Light.ttf');
	font-weight: 300;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Yantramanav';
	src: url('./assets/fonts/Yantramanav-Medium.ttf');
	font-weight: 500;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Yantramanav';
	src: url('./assets/fonts/Yantramanav-Regular.ttf');
	font-weight: 400;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Yantramanav';
	src: url('./assets/fonts/Yantramanav-Thin.ttf');
	font-weight: 100;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Rajdhani';
	src: url('./assets/fonts/Rajdhani-Light.ttf');
	font-weight: 200;
	font-stretch: 25% 151%;
  font-display: swap;
}
@font-face {
	font-family: 'Rajdhani';
	src: url('./assets/fonts/Rajdhani-Regular.ttf');
	font-weight: 400;
	font-stretch: 25% 151%;
  font-display: swap;
}

html, body {
    margin: 0;
    font-family: 'Yantramanav', sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    background-color: #F5F5F5;
    color: #1F1F1F;
}

h2 {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
}

#app {
  height: 85%;
}
</style>

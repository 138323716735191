
    import Vue from 'vue';
    import { defineComponent } from 'vue';

    export default defineComponent({
    name: 'AW-menu',
    props: {
        awImgSrc: String,
        activeItem: String,
    },
    data() {
        return {
            showMobileNav: false
        }
    },
    methods: {
        toggleShowMobileNav() {
            console.log(this.showMobileNav);
            this.showMobileNav = this.showMobileNav ? false : true;
            console.log(this.showMobileNav);
      },
    }
    });
